import { Autoplay, Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from 'react-router-dom';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import { BsFillCheckCircleFill } from "react-icons/bs"

const About = () => {
    return (
        <>
            <>
                <div className="rts-breadcrumb-area breadcrumb-bg bg_image">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 breadcrumb-1">
                                <h1 className="title">About Us</h1>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                <div className="bread-tag">
                                    <Link to="/" className="active">Home</Link>
                                    <span> / </span>
                                    <Link to="/about">
                                        About Us
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* end breadcrumb area */}
                {/* rts about us section start */}
                <div className="rts-about-area rts-section-gap" >
                    <div className="container">
                        <div className="row g-5" style={{alignItems:'center'}}>
                            <div className="col-lg-6">
                                <div className="about-image-v-inner">
                                    <div className="image-area">
                                        <img
                                            className="mt--110 img-1"
                                            src={require('./images/about/main/about-03.webp')}
                                            alt="BUsiness_image"
                                        />
                                        <img
                                            className="img-over"
                                            src={require('./images/about/main/about-04.webp')}
                                            alt="BUsiness_image"
                                        />  
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="about-progress-inner">
                                    <div className="title-area">
                                        <span>MORE ABOUT US</span>
                                        <h2 className="title">
                                            Unlock your company's potential with our top-notch global IT
                                            solutions.
                                        </h2>
                                        <span>Let's Know About Webvision.</span>
                                    </div>
                                    {/* inner start */}
                                    <div className="inner">
                                        <p className="disc">
                                            Webvision is a leading IT company committed to delivering
                                            exceptional digital solutions that empower businesses to thrive
                                            in the modern digital landscape. Established with a vision to
                                            revolutionize the industry, we have successfully served a
                                            diverse clientele with our innovative approach and expertise.
                                        </p>
                                        <div className="rts-progress-one-wrapper">
                                            <div className="single-progress">
                                                <div className="progress-top">
                                                    <p className="progress-title">Android Development</p>
                                                    <span className="persectage">95%</span>
                                                </div>
                                                <div className="meter cadetblue">
                                                    <span data-progress={98} style={{ width: '98%' }} />
                                                </div>
                                            </div>
                                            <div className="single-progress">
                                                <div className="progress-top">
                                                    <p className="progress-title">ios Development</p>
                                                    <span className="persectage">90%</span>
                                                </div>
                                                <div className="meter">
                                                    <span data-progress={90} style={{ width: '90%' }} />
                                                </div>
                                            </div>
                                            <div className="single-progress">
                                                <div className="progress-top">
                                                    <p className="progress-title">
                                                        Website &amp; Webapp Development
                                                    </p>
                                                    <span className="persectage">95%</span>
                                                </div>
                                                <div className="meter">
                                                    <span data-progress={95} style={{ width: '95%' }} />
                                                </div>
                                            </div>
                                            <div className="single-progress">
                                                <div className="progress-top">
                                                    <p className="progress-title">Cross platform Development</p>
                                                    <span className="persectage">95%</span>
                                                </div>
                                                <div className="meter">
                                                    <span data-progress={95} style={{ width: '95%' }} />
                                                </div>
                                            </div>
                                            <div className="single-progress">
                                                <div className="progress-top">
                                                    <p className="progress-title">UI-UX</p>
                                                    <span className="persectage">99%</span>
                                                </div>
                                                <div className="meter">
                                                    <span data-progress={99} style={{ width: '99%' }} />
                                                </div>
                                            </div>
                                        </div>
                                        <Link to="/contact" target='_blank' className="rts-btn btn-primary">
                                            Make an Appointment
                                        </Link>
                                    </div>
                                    {/* end */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* rts about us section end */}
                {/* rts about Our company section start */}
                <div className="rts-about-our-company-h2 rts-section-gap rts-about-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-7 col-lg-7 col-md-12 col-sm-12 order-xl-1 order-lg-1 order-md-2 order-sm-2 order-2 mt_sm--30">
                                <div className="title-area about-company">
                                    <span>OUR VENTURE</span>
                                    <h2 className="title">Our Vision , Our Missin , Our Value</h2>
                                </div>
                                <div className="about-company-wrapper">
                                    <p className="disc">
                                        Webvision is the one-stop destination that addresses all your
                                        technological needs to scale and grow your business.
                                    </p>
                                    <div className="rts-tab-style-one">
                                        <div className="d-flex align-items-start contoler-company">
                                            <div
                                                className="nav flex-column nav-pills me-3 button-area"
                                                id="v-pills-tab"
                                                role="tablist"
                                                aria-orientation="vertical"
                                            >
                                                <button
                                                    className="nav-link active"
                                                    id="v-pills-home-tab"
                                                    data-bs-toggle="pill"
                                                    data-bs-target="#v-pills-home"
                                                    type="button"
                                                    role="tab"
                                                    aria-controls="v-pills-home"
                                                    aria-selected="false"
                                                >
                                                    01. The Great Mission
                                                </button>
                                                <button
                                                    className="nav-link"
                                                    id="v-pills-profile-tab"
                                                    data-bs-toggle="pill"
                                                    data-bs-target="#v-pills-profile"
                                                    type="button"
                                                    role="tab"
                                                    aria-controls="v-pills-profile"
                                                    aria-selected="false"
                                                >
                                                    02. Amazing Vision
                                                </button>
                                                <button
                                                    className="nav-link"
                                                    id="v-pills-messages-tab"
                                                    data-bs-toggle="pill"
                                                    data-bs-target="#v-pills-messages"
                                                    type="button"
                                                    role="tab"
                                                    aria-controls="v-pills-messages"
                                                    aria-selected="true"
                                                >
                                                    03. Our Value
                                                </button>
                                            </div>
                                            <div className="tab-content" id="v-pills-tabContent">
                                                <div
                                                    className="tab-pane fade active show"
                                                    id="v-pills-home"
                                                    role="tabpanel"
                                                    aria-labelledby="v-pills-home-tab"
                                                >
                                                    {/* start tab content */}
                                                    <div className="rts-tab-content-one">
                                                        <p className="disc">
                                                            At Webvision, our mission is to provide cutting-edge
                                                            technology solutions that drive success for our clients.
                                                            We strive to be a reliable and trusted partner, offering
                                                            tailored services that align with their unique goals and
                                                            requirements. With a customer-centric focus, we aim to
                                                            build long-lasting relationships by delivering unmatched
                                                            value and unparalleled support.
                                                        </p>


                                                    </div>
                                                    {/* start tab content End */}
                                                </div>
                                                <div
                                                    className="tab-pane fade"
                                                    id="v-pills-profile"
                                                    role="tabpanel"
                                                    aria-labelledby="v-pills-profile-tab"
                                                >
                                                    {/* start tab content */}
                                                    <div className="rts-tab-content-one">
                                                        <p className="disc">
                                                            Unleashing Tomorrow's Possibilities: Our long-term vision is to redefine the IT landscape worldwide. We'll lead in Revolutionary innovations, forge unbreakable customer bonds, and revolutionize industries. Together, we'll shape a future where technology unlocks boundless opportunities for all.
                                                        </p>

                                                    </div>
                                                    {/* start tab content End */}
                                                </div>
                                                <div
                                                    className="tab-pane fade"
                                                    id="v-pills-messages"
                                                    role="tabpanel"
                                                    aria-labelledby="v-pills-messages-tab"
                                                >
                                                    {/* start tab content */}
                                                    <div className="rts-tab-content-one">
                                                        <div className="check-area">
                                                            <i> <BsFillCheckCircleFill /> </i>
                                                            <p className="disc">Excellence</p>
                                                        </div>
                                                        <div className="check-area">
                                                            <i> <BsFillCheckCircleFill /> </i>
                                                            <p className="disc">Transparency</p>
                                                        </div>
                                                        <div className="check-area">
                                                            <i> <BsFillCheckCircleFill /> </i>
                                                            <p className="disc">Honesty</p>
                                                        </div>
                                                    </div>
                                                    {/* start tab content End */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 order-xl-1 order-lg-1 order-md-1 order-sm-1 order-1">
                                <div className="about-company-thumbnail">
                                    <img
                                        src={require('./images/about/01.png')}
                                        alt="Business_company" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* rts about Our company section End */}
                {/* service accordion area */}
                <div className="rts-accordion-area service rts-section-gap">
                    <div className="accordion-service-bg bg_image ptb--120 ptb_md--80 ptb_sm--60">
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-6">
                                    <div className="accordion-service-inner">
                                        <div className="title-area-start">
                                            <span className="sub color-primary">OUR APPROACHES</span>
                                            <h2 className="title">
                                                Strategic Approaches for Effective Web Design &amp;
                                                Development Services
                                            </h2>
                                        </div>
                                        <div className="accordion-area">
                                            <div className="accordion" id="accordionExample">
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header">
                                                        <button className="accordion-button">
                                                            Cousulting With Client
                                                        </button>
                                                    </h2>
                                                </div>
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header" id="headingTwo">
                                                        <button className="accordion-button">
                                                            Requirement Gathering &amp; Quotation
                                                        </button>
                                                    </h2>
                                                </div>
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header" id="headingThree">
                                                        <button className="accordion-button">
                                                            Onboarding &amp; Development
                                                        </button>
                                                    </h2>
                                                </div>
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header" id="headingFour">
                                                        <button className="accordion-button">
                                                            Life long Business Relationships
                                                        </button>
                                                    </h2>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* service accordion area End */}
                {/* rts why choose us section area */}
                <div className="rts-faq-section rts-section-gapBottom rts-faq-bg bg_image">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-5 col-md-12 col-sm-12 col-12">
                                <div className="thumbnail-faq-four">
                                    <div className="global-business-pic">
                                        <img
                                            src={require('./images/about/industry-left.png')}
                                            alt="industry-left"
                                        />
                                        <p className="xl-business-text">
                                            <span>We provide global</span>
                                            <br />
                                            <span>solutions to diverse</span>
                                            <br />
                                            <span> industries</span>
                                            <br />
                                            <span>worldwide.</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-12 col-sm-12 col-12">
                                <div className="faq-two-inner">
                                    <div className="title-area-faq">
                                        <span className="sub color-primary">Areas of expertise</span>
                                        <h2 className="title">Our Extensive Sectoral Coverage</h2>
                                    </div>
                                    {/* faq accordion area */}
                                    <div className="industriescol2 industries-inc">
                                        <ul>
                                            <li>
                                                <span className="tooltip tooltip-effect-1">
                                                    <span className="tooltip-item">
                                                        <a
                                                            className="services lazybgnone"
                                                        >
                                                            &nbsp;
                                                        </a>
                                                    </span>
                                                    <span className="tooltip-content">Services</span>
                                                </span>
                                            </li>
                                            <li>
                                                <span className="tooltip tooltip-effect-1">
                                                    <span className="tooltip-item">
                                                        <a
                                                            className="realestate lazybgnone"
                                                        >
                                                            &nbsp;
                                                        </a>
                                                    </span>
                                                    <span className="tooltip-content">Real Estate</span>
                                                </span>
                                            </li>
                                            <li>
                                                <span className="tooltip tooltip-effect-1">
                                                    <span className="tooltip-item">
                                                        <a
                                                            className="furniture lazybgnone"
                                                        >
                                                            &nbsp;
                                                        </a>
                                                    </span>
                                                    <span className="tooltip-content">Furniture</span>
                                                </span>
                                            </li>
                                            <li>
                                                <span className="tooltip tooltip-effect-1">
                                                    <span className="tooltip-item">
                                                        <a
                                                            className="jewelry lazybgnone"
                                                        >
                                                            &nbsp;
                                                        </a>
                                                    </span>
                                                    <span className="tooltip-content">Jewelry</span>
                                                </span>
                                            </li>
                                            <li>
                                                <span className="tooltip tooltip-effect-1">
                                                    <span className="tooltip-item">
                                                        <a
                                                            className="hotel lazybgnone"
                                                        >
                                                            &nbsp;
                                                        </a>
                                                    </span>
                                                    <span className="tooltip-content">Hotel</span>
                                                </span>
                                            </li>
                                            <li>
                                                <span className="tooltip tooltip-effect-1">
                                                    <span className="tooltip-item">
                                                        <a
                                                            className="chemical lazybgnone"
                                                        >
                                                            &nbsp;
                                                        </a>
                                                    </span>
                                                    <span className="tooltip-content">Chemical</span>
                                                </span>
                                            </li>
                                            <li>
                                                <span className="tooltip tooltip-effect-1">
                                                    <span className="tooltip-item">
                                                        <a
                                                            className="pharma lazybgnone"
                                                        >
                                                            &nbsp;
                                                        </a>
                                                    </span>
                                                    <span className="tooltip-content">Pharma</span>
                                                </span>
                                            </li>
                                            <li>
                                                <span className="tooltip tooltip-effect-1">
                                                    <span className="tooltip-item">
                                                        <a
                                                            className="manufacture lazybgnone"
                                                        >
                                                            &nbsp;
                                                        </a>
                                                    </span>
                                                    <span className="tooltip-content">Manufacture</span>
                                                </span>
                                            </li>
                                            <li>
                                                <span className="tooltip tooltip-effect-1">
                                                    <span className="tooltip-item">
                                                        <a
                                                            className="advertising lazybgnone"
                                                        >
                                                            &nbsp;
                                                        </a>
                                                    </span>
                                                    <span className="tooltip-content">Advertising</span>
                                                </span>
                                            </li>
                                            <li>
                                                <span className="tooltip tooltip-effect-1">
                                                    <span className="tooltip-item">
                                                        <a
                                                            className="engineering lazybgnone"
                                                        >
                                                            &nbsp;
                                                        </a>
                                                    </span>
                                                    <span className="tooltip-content">Engineering</span>
                                                </span>
                                            </li>
                                            <li>
                                                <span className="tooltip tooltip-effect-1">
                                                    <span className="tooltip-item">
                                                        <a
                                                            className="finance lazybgnone"
                                                        >
                                                            &nbsp;
                                                        </a>
                                                    </span>
                                                    <span className="tooltip-content">Finance</span>
                                                </span>
                                            </li>
                                            <li>
                                                <span className="tooltip tooltip-effect-1">
                                                    <span className="tooltip-item">
                                                        <a className="food lazybgnone">
                                                            &nbsp;
                                                        </a>
                                                    </span>
                                                    <span className="tooltip-content">Food</span>
                                                </span>
                                            </li>
                                            <li>
                                                <span className="tooltip tooltip-effect-1">
                                                    <span className="tooltip-item">
                                                        <a
                                                            className="education lazybgnone"
                                                        >
                                                            &nbsp;
                                                        </a>
                                                    </span>
                                                    <span className="tooltip-content">education</span>
                                                </span>
                                            </li>
                                            <li>
                                                <span className="tooltip tooltip-effect-1">
                                                    <span className="tooltip-item">
                                                        <a
                                                            className="solar lazybgnone"
                                                        >
                                                            &nbsp;
                                                        </a>
                                                    </span>
                                                    <span className="tooltip-content"> Solar</span>
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                    {/* faq accordion area end */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* rts why choose us section area End */}
                {/* customers feed back area start */}
                <div className="rts-customer-feedback-area rts-section-gap bg-customer-feedback">
                    <div className="container">
                        <div className="row">
                            <div className="rts-title-area feedback team text-center">
                                <p className="pre-title">OUR TESTIMONIALS</p>
                                <h2 className="title">Client’s feedbacks</h2>
                            </div>
                        </div>
                        <div className="row g-5 mt--0">
                            <div className="swiper">
                                <div className="swiper-wrapper">
                                    <Swiper
                                        // install Swiper modules
                                        modules={[Autoplay, Navigation, Pagination, Scrollbar]}
                                        spaceBetween={50}
                                        slidesPerView={1}
                                        autoplay={{ delay: 3000 }}
                                        breakpoints={{

                                            1200: {
                                                slidesPerView: 2,
                                                spaceBetween: 20,
                                            },
                                            992: {
                                                slidesPerView: 2,
                                                spaceBetween: 20,
                                            },
                                            991: {
                                                slidesPerView: 1,
                                                spaceBetween: 20,
                                            },
                                            // when window width is >= 768px

                                        }}
                                    >
                                        <SwiperSlide>
                                            <div className="swiper-slide">
                                                <div className="testimopnial-wrapper-two">
                                                    <div className="test-header">
                                                        <div className="thumbnail">
                                                            <img
                                                                src={require('./images/testimonials/02.png')}
                                                                alt="" />
                                                        </div>
                                                        <div className="name-desig">
                                                            <h5 className="title">Shreekant Iyer</h5>
                                                            <span className="designation">Sarvayogam Group</span>
                                                        </div>
                                                    </div>
                                                    <div className="test-body">
                                                        <p className="disc">
                                                            "Choosing Webvision for our website was the best decision.
                                                            They brilliantly captured our vision, creating an impactful
                                                            platform that showcases our solar project. Their team's
                                                            expertise and dedication were evident throughout the
                                                            process. We're thrilled with the result and highly recommend
                                                            their exceptional web development services."
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </SwiperSlide>

                                        <SwiperSlide>
                                            <div className="swiper-slide">
                                                <div className="testimopnial-wrapper-two">
                                                    <div className="test-header">
                                                        <div className="thumbnail">
                                                            <img
                                                                src={require('./images/testimonials/03.png')}
                                                                alt="" />
                                                        </div>
                                                        <div className="name-desig">
                                                            <h5 className="title">Dhiraj Nakarani</h5>
                                                            <span className="designation">Swagatam Mobile</span>
                                                        </div>
                                                    </div>
                                                    <div className="test-body">
                                                        <p className="disc">
                                                            Webvision's CRM for Swagatam Mobile transformed our repair
                                                            shop! Their seamless system simplified our processes,
                                                            enhanced customer interactions, and improved efficiency.
                                                            Working with their team was a pleasure, and the results
                                                            exceeded our expectations. Highly recommended for anyone
                                                            seeking top-notch CRM solutions!
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </SwiperSlide>

                                        <SwiperSlide>
                                            <div className="swiper-slide">
                                                <div className="testimopnial-wrapper-two">
                                                    <div className="test-header">
                                                        <div className="thumbnail">
                                                            <img
                                                                src={require('./images/testimonials/04.png')}
                                                                alt="" />
                                                        </div>
                                                        <div className="name-desig">
                                                            <h5 className="title">Bhavesh Harsora</h5>
                                                            <span className="designation">Geeta Transformer</span>
                                                        </div>
                                                    </div>
                                                    <div className="test-body">
                                                        <p className="disc">
                                                            Webvision's CRM for Geeta Transformer was a game-changer!
                                                            Their customized solution streamlined our manufacturing
                                                            processes and improved customer relations. Their team's
                                                            expertise and support were exceptional, making the
                                                            implementation smooth. Thanks to Webvision, our efficiency
                                                            skyrocketed. Highly recommend their CRM services!
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </SwiperSlide>

                                        <SwiperSlide>
                                            <div className="swiper-slide">
                                                <div className="testimopnial-wrapper-two">
                                                    <div className="test-header">
                                                        <div className="thumbnail">
                                                            <img
                                                                src={require('./images/testimonials/05.png')}
                                                                alt="" />
                                                        </div>
                                                        <div className="name-desig">
                                                            <h5 className="title">Dr. Bipin Lathiya</h5>
                                                            <span className="designation">
                                                                Ornate Painless Dental Hospital
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="test-body">
                                                        <p className="disc">
                                                            Webvision truly transformed our online presence with the
                                                            stunning website for Ornat Dental Clinic. Their team's
                                                            creativity and attention to detail were outstanding. The
                                                            website's user-friendly design and engaging content
                                                            impressed both our patients and staff. Highly recommended
                                                            for top-notch web development services!
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </SwiperSlide>

                                        <SwiperSlide>
                                            <div className="swiper-slide">
                                                <div className="testimopnial-wrapper-two">
                                                    <div className="test-header">
                                                        <div className="thumbnail">
                                                            <img
                                                                src={require('./images/testimonials/06.png')}
                                                                alt="" />
                                                        </div>
                                                        <div className="name-desig">
                                                            <h5 className="title">Pankaj Prajapati</h5>
                                                            <span className="designation"> Lotus Enterprise</span>
                                                        </div>
                                                    </div>
                                                    <div className="test-body">
                                                        <p className="disc">
                                                            "Webvision's expertise shone through in crafting our
                                                            impressive website for Lotus Enterprise. Their team's
                                                            professionalism and seamless execution brought our wholesale
                                                            business to life online. The website's design and
                                                            functionality exceeded our expectations, attracting more
                                                            customers and boosting our sales. Highly recommend Webvision
                                                            for exceptional web development!"
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </SwiperSlide>

                                        <SwiperSlide>
                                            <div className="swiper-slide">
                                                <div className="testimopnial-wrapper-two">
                                                    <div className="test-header">
                                                        <div className="thumbnail">
                                                            <img
                                                                src={require('./images/testimonials/06.png')}
                                                                alt="" />
                                                        </div>
                                                        <div className="name-desig">
                                                            <h5 className="title">Pankaj Patel</h5>
                                                            <span className="designation">Shree Shubh Laxmi Sales</span>
                                                        </div>
                                                    </div>
                                                    <div className="test-body">
                                                        <p className="disc">
                                                            "Webvision's exceptional work on our Shree Shubh Laxmi Sales
                                                            website exceeded all expectations. Their team brilliantly
                                                            captured our product-based repurchase plan, creating a
                                                            dynamic platform that resonates with our customers. The
                                                            website's user-friendly interface and seamless functionality
                                                            have contributed to our business's success. Highly recommend
                                                            their top-notch web development services!"
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </SwiperSlide>

                                        <SwiperSlide>
                                            <div className="swiper-slide">
                                                <div className="testimopnial-wrapper-two">
                                                    <div className="test-header">
                                                        <div className="thumbnail">
                                                            <img
                                                                src={require('./images/testimonials/03.png')}
                                                                alt="" />
                                                        </div>
                                                        <div className="name-desig">
                                                            <h5 className="title">Shubham Kikani</h5>
                                                            <span className="designation">Vraj Mathura Impex</span>
                                                        </div>
                                                    </div>
                                                    <div className="test-body">
                                                        <p className="disc">
                                                            "Webvision's expertise brought brilliance to our Vraj
                                                            Mathura Impex website. Their team's creative flair and
                                                            attention to detail perfectly showcased our diamond
                                                            business. The website's captivating design and smooth
                                                            navigation impressed our clients, elevating our online
                                                            presence. Highly recommended for exceptional web development
                                                            services!"
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    </Swiper>
                                </div>
                                {/* <div className="swiper-button-next" />
                                <div className="swiper-button-prev" /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </>

        </>
    )
}
export default About;