import { Link } from "react-router-dom"

const Careers = () => {
    return (
        <>
            <>
                <div className="rts-breadcrumb-area breadcrumb-bg bg_image">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 breadcrumb-1">
                                <h1 className="title">Career</h1>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                <div className="bread-tag">
                                    <Link to="/"  className="active">Home</Link>
                                    <span> / </span>
                                    <Link to="/careers">
                                        Careers
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* end breadcrumb area */}
                <section className="opportunities-section">
                    <div className="container">
                        <div className="who-we-title">
                            <h2 className="title text-center">Join the dream team</h2>
                            <p className="font-static-16 font-static-gray font-weight-normal text-center">
                                If you’re passionate about your dreams, Webvision is here to fuel your
                                passion. <br />
                                We’re all about exploring, experimenting, and developing new ideas.
                            </p>
                        </div>
                        <div className="row justify-content-evenly ">
                            <div className="col-12 col-sm-12 col-md-6 col-lg-6 mt-5 pb_sm--80">
                                <div
                                    className="vacancy-header animate__fadeInUp wow animated"
                                    data-wow-duration="1s"
                                    data-wow-delay=".2s"
                                >
                                    <span className="font-static-16 font-static-black font-weight-normal">
                                        Adajan, Surat
                                    </span>
                                    <div className="job-title">
                                        {/* <input type="hidden" id="careerid"> */}
                                        <h2 className="font-static-24 font-static-black font-weight-500">
                                            Telecaller
                                            <br />
                                             (Female)
                                        </h2>
                                        <div className="number-opening text-center">
                                            <div className="numbering bg-blue">
                                                <p className="font-static-24 font-weight-normal font-static-white">
                                                    1
                                                </p>
                                            </div>
                                            <small>Opening(s)</small>
                                        </div>
                                    </div>
                                    <div className="job-details">
                                        <h4 className="font-static-16 font-weight-600 font-static-black">
                                            Experience:
                                        </h4>
                                        <p className="font-static-18 font-weight-normal fonbt-static-black">
                                            1+ Years
                                        </p>
                                    </div>
                                    <div className="job-details">
                                        <h4 className="font-static-16 font-weight-600 font-static-black">
                                            Technical Skills:
                                        </h4>
                                        <p className="font-static-18 font-weight-normal fonbt-static-black"></p>
                                        <ul>
                                            <li className="intro">
                                                Lead Generation Skills, cold calling Skill, Business Development, Communication Skills, Microsoft Office, Product Knowledge
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="job-details">
                                        <h4 className="font-static-16 font-weight-600 font-static-black">
                                            Optional Skills:
                                        </h4>
                                        <p className="font-static-18 font-weight-normal fonbt-static-black"></p>
                                        <ul>
                                            <li className="intro">
                                            A successful telecaller representative must be excellent at communication and must have superior people skills.
                                            </li>
                                        </ul>
                                    </div>
                                    {/* <div className="job-details">
                                        <h4 className="font-static-16 font-weight-600 font-static-black">
                                            Optional Skills:
                                        </h4>
                                        <p className="font-static-18 font-weight-normal fonbt-static-black"></p>
                                        <ul>
                                            <li className="intro">
                                                Git, Ubuntu/windows/mac os, Aws, Troubleshoot, Google api,
                                                Payment getaways
                                            </li>
                                        </ul>
                                    </div> */}
                                    <div className="view-btn">
                                        <a
                                            href="https://docs.google.com/forms/d/e/1FAIpQLScUiiUuBF68TGh_bBiMJaHUqwzYXNr9G9VesHXe0wPY1_ssSQ/viewform?usp=sf_link"
                                            target="_blank"
                                            className="bg-blue btn btn-primarys font-static-18 font-static-white font-weight-500"
                                        >
                                            Apply
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-12 col-md-6 col-lg-6 mt-5">
                                <div
                                    className="vacancy-header animate__fadeInUp wow animated"
                                    data-wow-duration="1s"
                                    data-wow-delay=".2s"
                                >
                                    <span className="font-static-16 font-static-black font-weight-normal">
                                        Adajan, Surat
                                    </span>
                                    <div className="job-title">
                                        {/* <input type="hidden" id="careerid"> */}
                                        <h2 className="font-static-24 font-static-black font-weight-500">
                                            BDE
                                        </h2>
                                        <div className="number-opening text-center">
                                            <div className="numbering bg-blue">
                                                <p className="font-static-24 font-weight-normal font-static-white">
                                                    2
                                                </p>
                                            </div>
                                            <small>Opening(s)</small>
                                        </div>
                                    </div>
                                    <div className="job-details">
                                        <h4 className="font-static-16 font-weight-600 font-static-black">
                                            Experience:
                                        </h4>
                                        <p className="font-static-18 font-weight-normal fonbt-static-black">
                                            2-3 Years
                                        </p>
                                    </div>
                                    <div className="job-details">
                                        <h4 className="font-static-16 font-weight-600 font-static-black">
                                            Technical Skills:
                                        </h4>
                                        <p className="font-static-18 font-weight-normal fonbt-static-black"></p>
                                        <ul>
                                            <li className="intro">
                                                Sales skills, Marketing skills, Business intelligence skills,
                                                Project ...
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="job-details">
                                        <h4 className="font-static-16 font-weight-600 font-static-black">
                                            Optional Skills:
                                        </h4>
                                        <p className="font-static-18 font-weight-normal fonbt-static-black"></p>
                                        <ul>
                                            <li className="intro">
                                                Cold Calling, Lead Generation, Possesses a High Curiosity
                                                Quotient. 
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="view-btn">
                                        <a
                                            href="https://docs.google.com/forms/d/e/1FAIpQLScUiiUuBF68TGh_bBiMJaHUqwzYXNr9G9VesHXe0wPY1_ssSQ/viewform?usp=sf_link"
                                            target="_blank"
                                            className="bg-blue btn btn-primarys font-static-18 font-static-white font-weight-500"
                                        >
                                            Apply
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>

        </>
    )
}
export default Careers