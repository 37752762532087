import companyLogo from './images/contact/shape/location.svg';
import emailicon from './images/contact/shape/02.svg';
import callus from './images/contact/shape/01.svg';
import sstation from './images/contact/shape/03.svg';
import { Link } from 'react-router-dom';
import { useState } from 'react';
// import axios from "axios";

const Contact = () => {
    let [fullname, pickName] = useState("");
    let [nameerror, updateNameError] = useState("");

    let [email, pickEmail] = useState("");
    let [emaileeerror, updateEmailError] = useState("");

    let [message, pickMessage] = useState("");
    let [messageeerror, updateMessageError] = useState("");

    let [subject, pickSubject] = useState("");
    let [subjecterror, updateSubjectError] = useState("");

    let[mobile,pickMobile] = useState("");
    let[mobileeerror, updateMobileError] = useState("");

    const save = () => {
        let formstatus = true

        if (fullname === "") {
            updateNameError("Required Name !");
            formstatus = false;
        } else {
            updateNameError("");

        }
        //email validation
        let filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        if (!filter.test(email)) {
            updateEmailError("Required Email  !");
            formstatus = false;
        } else {
            updateEmailError("");
        }

        //message validation
        if (message === "") {
            updateMessageError("Required Message  !");
            formstatus = false;
        } else {
            updateMessageError("");
        }
        if (formstatus === true) {
            alert("Plese waite submitting to server....");
        }

           //mobile no validation
           var moattern = /^[0]?[6789]\d{9}$/;
           if( ! moattern.test(mobile) ){
               updateMobileError("Required Mobile  !");
               formstatus = false; 
           }else{
               updateMobileError("");
           }

        //subject validation
        if (subject === "") {
            updateSubjectError("Required Subject  !");
            formstatus = false;
        } else {
            updateSubjectError("");
        }

        if (formstatus === true) {
            alert("Plese waite submitting to server....");
        }

    }

    return (
        <>
            <>
                {/* start breadcrumb area */}
                <div className="rts-breadcrumb-area breadcrumb-bg bg_image">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 breadcrumb-1">
                                <h1 className="title">Contact Us</h1>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                <div className="bread-tag">
                                    <Link to="/" className="active">Home</Link>
                                    <span> / </span>
                                    <Link to="/contact">
                                        Contact Us
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* end breadcrumb area */}
                {/* contact single area start */}
                <div className="rts-contact-area rts-section-gap">
                    <div className="container">
                        <div className="row g-5">
                            {/* single contact area */}
                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="single-contact-one-inner">
                                    <div className="thumbnail">
                                        <img
                                            src={require('./images/contact/call_113.webp')}
                                            alt="conact" />

                                    </div>
                                    <div className="content">
                                        <div className="icone">
                                            <a href="tel:+918980384148"> <img src={callus} alt="conact" /></a>
                                        </div>
                                        <div className="info">
                                            <span>Call Us 24/7</span>
                                            <a href="tel:+918980384148">
                                                <h6>+91 8980384148</h6>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* single contact area end */}
                            {/* single contact area */}
                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="single-contact-one-inner">
                                    <div className="thumbnail">
                                        <img
                                            src={require('./images/contact/msg.webp')}
                                            alt="conact" />
                                    </div>
                                    <div className="content">
                                        <div className="icone">

                                            <img src={emailicon} alt="conact" />
                                        </div>
                                        <div className="info">
                                            <span>Make A Quote</span>
                                            <a href="mailto:support@webvisioninfotech.com" target='_blank'>
                                                <h6>
                                                    support@webvisioninfotech.
                                                    <br />
                                                    com
                                                </h6>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* single contact area end */}
                            {/* single contact area */}
                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="single-contact-one-inner">
                                    <div className="thumbnail">
                                        <img
                                            src={require('./images/contact/location.webp')}
                                            alt="conact" />
                                    </div>
                                    <div className="content">
                                        <div className="icone">
                                            <img
                                                src={sstation}
                                                alt="conact" />
                                        </div>
                                        <div className="info">
                                            <span>Service Station</span>
                                            <a href="https://goo.gl/maps/jgR6yBnkhnG97BnN9" target='_blank'>
                                                <h6>
                                                   
                                                    403-The Galleria Business hub-2, Mahavir chowk, Yogi chowk 
                                                </h6>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* single contact area end */}
                        </div>
                    </div>
                </div>
                {/* conact single area end */}
                {/* bizup map area start */}
                <div className="rts-contact-map-area">
                    <div className="contaciner-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="contact-map-area-fluid">
                                    <iframe
                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3719.3376577449744!2d72.88660209999999!3d21.218455!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be04f96e5fbbae9%3A0xe32b8ec43132af35!2sWebvision%20Infotech!5e0!3m2!1sen!2sin!4v1712559111934!5m2!1sen!2sin"
                                        width={600}
                                        height={450}
                                        style={{ border: 0 }}
                                        allowFullScreen=""
                                        loading="lazy"
                                        referrerPolicy="no-referrer-when-downgrade"
                                    />

                                    <img src={companyLogo} className="location" alt="Business_map" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* bizup map area end */}
                {/* conact us form fluid start */}
                <div className="rts-contact-form-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="rts-contact-fluid rts-section-gap">
                                    <div className="rts-title-area contact-fluid text-center mb--50">
                                        <p className="pre-title">Get In Touch</p>
                                        <h2 className="title">Needs Help? Let’s Get in Touch</h2>
                                    </div>
                                    <div className="form-wrapper">
                                        <div id="form-messages" />
                                        <form
                                            action="https://formsubmit.co/support@webvisioninfotech.com"
                                            method="post"
                                        >
                                            <div className="row">
                                                <div className='col-md-6'>
                                                    <input
                                                        type="text"
                                                        name="name"
                                                        placeholder="Your Name"
                                                        onChange={obj => pickName(obj.target.value)}
                                                        required
                                                    />
                                                    <small className='me-3'>{nameerror}</small>
                                                </div>
                                                <div className='col-md-6'>
                                                    <input
                                                        type="email"
                                                        name="email"
                                                        placeholder="Email Address"
                                                        onChange={obj => pickEmail(obj.target.value)}
                                                        required
                                                    />
                                                    <small >{emaileeerror}</small>
                                                </div>

                                                <div className='col-md-6'>
                                                    <input
                                                        type="number"
                                                        name="Mobile"
                                                        placeholder="Mobile Number"
                                                        required
                                                        onChange={obj=>pickMobile(obj.target.value)}
                                                    />
                                                       <small>{mobileeerror}</small>
                                                </div>

                                                <div className='col-md-6'>
                                                    <input
                                                        type="text"
                                                        name="subject"
                                                        placeholder="Your Subject"
                                                        required
                                                        onChange={obj => pickSubject(obj.target.value)}
                                                    />
                                                        <small>{subjecterror}</small>
                                                </div>
                                            </div>

                                            <textarea
                                                placeholder="Type Your Message"
                                                name="message"
                                                defaultValue={""}
                                                onChange={obj => pickMessage(obj.target.value)}
                                                required
                                                className='mt-4'
                                            />
                                            <div className=''>
                                                <small>{messageeerror}</small>
                                            </div>
                                            <button onClick={save} type="submit" className="rts-btn btn-primary">
                                                Send Message
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* conact us form fluid end */}
            </>

        </>
    )
}
export default Contact;